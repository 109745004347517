import React, { useCallback, useMemo, memo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

// Imports => Constants
import {
  DATETIME_FORMATS,
  ICONS,
  KEYS,
  SIZES,
  THEMES,
  TITLES,
} from '@constants';

// Imports => Utilities
import {
  AcIsSet,
  AcFormatInternalURI,
  AcFormatDate,
  AcGetDaysRemaining,
} from '@utils';

// Imports => Atoms
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';

const _CLASSES = {
  MAIN: 'ac-contract-notification',
  CONTENT: 'ac-contract-notification__content',
  NAME: 'ac-contract-notification__name',
  MESSAGE: 'ac-contract-notification__message',
  DAYS_LEFT: 'ac-contract-notification__days-left',
  ICON: {
    MAIN: 'ac-contract-notification__icon',
    PAST: 'ac-contract-notification__icon--past',
  },
  DISMISS: 'ac-contract-notification__dismiss',
};

const AcContractNotification = ({ notification, data, dismiss }) => {
  const handleDismiss = (event) => {
    if (event && event.persist) event.persist();
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    if (dismiss) dismiss(notification, data);
  };

  const getDismissIconClassNames = useMemo(() => {
    return clsx(_CLASSES.DISMISS);
  }, []);

  const getIconClassNames = useCallback((remaining) => {
    return clsx(_CLASSES.ICON.MAIN, remaining <= 0 && _CLASSES.ICON.PAST);
  }, []);

  const getNameClassNames = useMemo(() => {
    return clsx(_CLASSES.NAME);
  }, []);

  const getMessageClassNames = useMemo(() => {
    return clsx(_CLASSES.MESSAGE);
  }, []);

  const getDaysLeftClassNames = useMemo(() => {
    return clsx(_CLASSES.DAYS_LEFT);
  }, []);

  const getContentClassNames = useMemo(() => {
    return clsx(_CLASSES.CONTENT);
  }, []);

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  }, []);

  const getRoute = useMemo(() => {
    if (!AcIsSet(data)) return null;

    const { id, name } = data;

    const route = AcFormatInternalURI(
      { id, entity: KEYS.CONTRACT },
      `View contract: ${name}`
    );

    return route;
  }, [data]);

  const getDaysRemaining = useMemo(() => {
    if (!AcIsSet(data)) return null;

    const { date_end } = data;

    const remaining = AcGetDaysRemaining(date_end);

    return remaining;
  }, [data]);

  const renderIcon = useMemo(() => {
    if (!AcIsSet(data)) return null;

    const remaining = getDaysRemaining;

    const icon =
      remaining > 0
        ? ICONS.CLIPBOARD_PULSE_OUTLINE
        : ICONS.CLIPBOARD_ALERT_OUTLINE;

    return <AcIcon icon={icon} className={getIconClassNames(remaining)} />;
  }, [data, getDaysRemaining]);

  const renderName = useMemo(() => {
    if (!AcIsSet(data)) return null;

    const { name } = data;
    const remaining = getDaysRemaining;

    return (
      <div
        className={getNameClassNames}
        dangerouslySetInnerHTML={{
          __html: `Contract ${name} ${
            remaining < 0 ? 'has ended' : 'is ending soon'
          }`,
        }}
      />
    );
  }, [data, getDaysRemaining]);

  const renderDaysLeft = useMemo(() => {
    if (!AcIsSet(data)) return null;

    return (
      <div
        className={getDaysLeftClassNames}
        dangerouslySetInnerHTML={{
          __html: `${getDaysRemaining} days remaining`,
        }}
      />
    );
  }, [data, getDaysRemaining]);

  const renderMessage = useMemo(() => {
    if (!AcIsSet(data)) return null;

    const { name, company, date_end } = data;

    const remaining = getDaysRemaining;
    const expiration_date = AcFormatDate(
      date_end,
      null,
      DATETIME_FORMATS.RAW_DATETIME_WITH_YEAR_NO_TIME
    );

    let message = `Contract <u>${name}</u> of ${company.name} is reaching its end date on <u>${expiration_date}</ul>.`;
    if (remaining < 0) {
      message = `Contract <u>${name}</u> of ${company.name} has ended on <u>${expiration_date}</ul>.`;
    }

    return (
      <div
        className={getMessageClassNames}
        dangerouslySetInnerHTML={{
          __html: message,
        }}
      />
    );
  }, [data, getDaysRemaining]);

  return (
    <Link to={getRoute} className={getMainClassNames}>
      <AcRipple theme={THEMES.PITCH} size={SIZES.SMALL} simple />
      {renderIcon}
      <div className={getContentClassNames}>
        {renderName}
        {renderMessage}
      </div>
      <AcIcon
        icon={ICONS.CLOSE}
        title={TITLES.DISMISS_MESSAGE}
        className={getDismissIconClassNames}
        callback={handleDismiss}
      />
    </Link>
  );
};

export default memo(AcContractNotification);
