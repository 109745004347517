import React, { useState, useEffect, useMemo, memo } from 'react';
import DatePicker from 'react-datepicker/dist/es/index.js';
import clsx from 'clsx';

// Imports => Constants
import { DATETIME_FORMATS, TYPES } from '@constants';

// Imports => Utilities
import { AcUUID, AcIsSet } from '@utils';

const _CLASSES = {
  WRP: 'ac-datepicker-input',
  START: 'ac-datepicker-input--start',
  END: 'ac-datepicker-input--end',
  INLINE: 'ac-datepicker-input--inline',
  EXPIRATION: 'ac-datepicker-input--expiration',
  LABEL: 'ac-datepicker-input__label',
  DATEPICKER: {
    INPUT: 'ac-datepicker-input__input',
    LABEL: 'ac-datepicker-input__label',
  },
};

const AcDatepickerInput = ({
  id = AcUUID(),
  value = '',
  label,
  placeholder = 'dd/mm/yyyy',
  name,
  onclick,
  callback,
  validation,
  start = null,
  end = null,
  min = null,
  range = null,
  disabled = false,
  readonly = false,
  inline = false,
  dateFormat = DATETIME_FORMATS.DATE_UNICODE,
  maxDate = null,
  minDate = null,
}) => {
  const handleClick = (data) => {
    if (onclick) onclick();
  };

  const handleChange = (data) => {
    if (callback) callback(null, name, data, TYPES.DATE);
  };

  const getRestProps = useMemo(() => {
    const result = {};

    if (AcIsSet(min)) result.minDate = min;
    if (AcIsSet(range)) {
      if (range === 'start') result.selectsStart = true;
      if (range === 'end') result.selectsEnd = true;
    }
    if (AcIsSet(start)) result.startDate = start;
    if (AcIsSet(end)) result.endDate = end;
    if (AcIsSet(inline) && inline === true) result.inline = true;
    if (AcIsSet(maxDate)) result.maxDate = maxDate;
    if (AcIsSet(minDate)) result.minDate = minDate;

    return result;
  }, [start, end, min, dateFormat, range]);

  const getDatepickerLabelClassNames = useMemo(() => {
    return clsx(_CLASSES.DATEPICKER.LABEL);
  }, []);

  const getDatepickerInputClassNames = useMemo(() => {
    return clsx(_CLASSES.DATEPICKER.INPUT);
  }, []);

  const getDatepickerWrpClassNames = useMemo(() => {
    return clsx(
      _CLASSES.WRP,
      range && _CLASSES[range.toUpperCase()],
      inline && _CLASSES.INLINE
    );
  }, [range, inline]);

  return (
    <div className={getDatepickerWrpClassNames} onClick={handleClick}>
      {label && (
        <label
          htmlFor={id}
          className={getDatepickerLabelClassNames}
          dangerouslySetInnerHTML={{
            __html: label,
          }}
        />
      )}
      <DatePicker
        id={id}
        selected={value}
        onChange={handleChange}
        dateFormat={DATETIME_FORMATS.DATE_UNICODE}
        placeholderText={placeholder || 'Click to select a date'}
        className={getDatepickerInputClassNames}
        disabled={disabled}
        readOnly={readonly}
        {...getRestProps}
      />
    </div>
  );
};

export default memo(AcDatepickerInput);
