import React, { useMemo } from 'react';
import { InfoWindow } from './ac-info-window';
import { useCluster } from '../hooks/use-cluster';
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';
import { ICONS, THEMES, SIZES } from '@constants';

/**
 * React FC.
 * Renders a custom Cluster InfoWindow on the Google map.
 * It deals with multiple Markers in the cluster, so this component knows how to navigate through them.
 */
export const ClusterInfoWindow = ({ marker: cluster, children, ...rest }) => {
  const {
    currentMarkerNumber,
    totalMarkers,
    currentMarker,
    prevMarker,
    nextMarker,
  } = useCluster(cluster);

  const header = useMemo(
    () => (
      <>
        <div className={'ac-info-window-bubble--header__counter'}>
          <div style={{ position: 'relative' }} onClick={prevMarker}>
            <AcIcon
              icon={ICONS.CHEVRON_LEFT}
              className={'ac-info-window-bubble--header__icon'}
            />
            <AcRipple theme={THEMES.OMEGA} size={SIZES.SMALL} />
          </div>
          <span>
            {currentMarkerNumber + 1} van {totalMarkers}
          </span>
          <div style={{ position: 'relative' }} onClick={nextMarker}>
            <AcIcon
              icon={ICONS.CHEVRON_RIGHT}
              className={'ac-info-window-bubble--header__icon'}
            />
            <AcRipple theme={THEMES.OMEGA} size={SIZES.SMALL} />
          </div>
        </div>
      </>
    ),
    [nextMarker, prevMarker, currentMarkerNumber, totalMarkers]
  );

  return (
    <InfoWindow {...rest} header={header} marker={currentMarker}>
      {children}
    </InfoWindow>
  );
};
