import React, { useMemo, memo } from 'react';
import clsx from 'clsx';

// Imports => Constants
import { THEMES, SIZES } from '@constants';

// Imports => Atoms
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';

const _CLASSES = {
	MAIN: 'ac-tag',
	DISABLED: 'ac-tag--disabled',
	ACTIVE: 'ac-tag--active',
};

const AcTag = ({ id, label, disabled, callback, active, className }) => {
	const handleCallback = (event) => {
		if (event && event.persist) event.persist();
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.stopPropagation) event.stopPropagation();

		if (disabled) return;
		if (callback) callback(id);
	};

	const getMainClassNames = useMemo(() => {
		return clsx(
			_CLASSES.MAIN,
			disabled && _CLASSES.DISABLED,
			active && _CLASSES.ACTIVE,
			className
		);
	}, [disabled, active, className]);

	return (
		<div
			disabled={disabled}
			className={getMainClassNames}
			onClick={handleCallback}
		>
			<span
				dangerouslySetInnerHTML={{
					__html: label,
				}}
			/>
			<AcRipple theme={THEMES.WHITE} size={SIZES.SMALL} simple />
		</div>
	);
};

export default memo(AcTag);
