import React, { useMemo, memo } from 'react';
import { Fade } from 'react-awesome-reveal';
import clsx from 'clsx';

// Imports => Constants
import { ICONS } from '@constants';

// Imports => Atoms
import AcIcon from '@atoms/ac-icon/ac-icon.web';

const _CLASSES = {
	MAIN: 'ac-empty-block',
	CONTENT: 'ac-empty-block__content',
	ICON: 'ac-empty-block__icon',
	MESSAGE: 'ac-empty-block__message',
};

const AcEmptyBlock = ({ message = 'No data found to display.' }) => {
	const getMessageClassNames = useMemo(() => {
		return clsx(_CLASSES.MESSAGE);
	}, []);

	const getIconClassNames = useMemo(() => {
		return clsx(_CLASSES.ICON);
	}, []);

	const getContentClassNames = useMemo(() => {
		return clsx(_CLASSES.CONTENT);
	}, []);

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN);
	}, []);

	return (
		<Fade duration={200} appear>
			<div className={getMainClassNames}>
				<div className={getContentClassNames}>
					<AcIcon icon={ICONS.UNAVAILABLE} className={getIconClassNames} />
					<p
						className={getMessageClassNames}
						dangerouslySetInnerHTML={{
							__html: message,
						}}
					/>
				</div>
			</div>
		</Fade>
	);
};

export default memo(AcEmptyBlock);
