import React, { useMemo, memo } from 'react';
import clsx from 'clsx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import { AcIsSet, AcIsUndefined, AcFormatSecondsToHms } from '@utils';

// Imports => Atoms
import AcCard from '@atoms/ac-card/ac-card.web';

const _CLASSES = {
	MAIN: 'ac-kpi-card',
	WHITE: 'ac-kpi-card--white',
	OMEGA: 'ac-kpi-card--omega',
	ALPHA: 'ac-kpi-card--alpha',
	PITCH: 'ac-kpi-card--pitch',
	CONTENT: 'ac-kpi-card__content',
	TITLE: 'ac-kpi-card__title',
	LABEL: 'ac-kpi-card__label',
	VALUE: 'ac-kpi-card__value',
	COMPARISON: 'ac-kpi-card__comparison',
	COMPARISON_POSITIVE: 'ac-kpi-card__comparison--positive',
	COMPARISON_NEGATIVE: 'ac-kpi-card__comparison--negative',
	SUBTEXT: 'ac-kpi-card__subtext',
	PROGRESS: {
		MAIN: 'ac-kpi-card__progress',
		TRACKER: 'ac-kpi-card__progress-tracker',
	},
};

const AcKpiCard = ({
	theme,
	title,
	label,
	value = 0,
	total = 0,
	comparison,
	unit,
	type,
}) => {
	const getProgressTrackerClassNames = useMemo(() => {
		return clsx(_CLASSES.PROGRESS.TRACKER);
	}, []);

	const getProgressClassNames = useMemo(() => {
		return clsx(_CLASSES.PROGRESS.MAIN);
	}, []);

	const getSubtextClassNames = useMemo(() => {
		return clsx(_CLASSES.SUBTEXT);
	}, []);

	const getComparisonClassNames = useMemo(() => {
		let formatted = parseInt(comparison, 10);
		if (isNaN(formatted)) formatted = 0;
		const sign = formatted > 0 ? 'POSITIVE' : formatted < 0 ? 'NEGATIVE' : null;
		return clsx(_CLASSES.COMPARISON, sign && _CLASSES[`COMPARISON_${sign}`]);
	}, [comparison]);

	const getValueClassNames = useMemo(() => {
		return clsx(_CLASSES.VALUE);
	}, [value]);

	const getLabelClassNames = useMemo(() => {
		return clsx(_CLASSES.LABEL);
	}, [label]);

	const getContentClassNames = useMemo(() => {
		return clsx(_CLASSES.CONTENT);
	});

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN, theme && _CLASSES[theme.toUpperCase()]);
	}, []);

	const formatValue = useMemo(() => {
		let result = value;

		if (!AcIsSet(value)) return '-';

		switch (type) {
			case KEYS.DURATION:
				result = AcFormatSecondsToHms(value);
				break;

			case KEYS.JOULE:
				result = `${value} kJ`;
				break;

			case KEYS.BAR:
				result = `${value} bar`;
				break;

			case KEYS.PROGRESS:
				// result = `${value}<sub>/${total}</sub>`;
				result = value;
				break;
			default:
		}

		return result;
	}, [value, total, type]);

	const formatComparison = useMemo(() => {
		if (!AcIsSet(comparison)) return '&nbsp;';

		let result = parseInt(comparison, 10);
		if (isNaN(result)) result = 0;
		let sign = '';

		switch (unit) {
			case 'piles':
			case 'records':
				sign = result > 0 ? '+' : result < 0 ? '-' : '';
				result = `${sign}${Math.abs(result)} ${unit}`;
				break;

			case '%':
				sign = result > 0 ? '+' : result < 0 ? '-' : '';
				result = `${sign}${Math.abs(result)}%`;
				break;
			default:
		}

		return result;
	}, [comparison, unit]);

	return (
		<AcCard flat className={getMainClassNames}>
			<div className={getContentClassNames}>
				{AcIsSet(label) && (
					<span
						className={getLabelClassNames}
						dangerouslySetInnerHTML={{
							__html: label,
						}}
					/>
				)}
				{!AcIsUndefined(value) && (
					<div className={getValueClassNames}>
						<span
							dangerouslySetInnerHTML={{
								__html: formatValue,
							}}
						/>
					</div>
				)}
				{!AcIsUndefined(comparison) && (
					<div className={getComparisonClassNames}>
						<span
							dangerouslySetInnerHTML={{
								__html: formatComparison,
							}}
						/>
					</div>
				)}
				<span
					className={getSubtextClassNames}
					dangerouslySetInnerHTML={{
						__html: 'Since last week',
					}}
				/>
			</div>
		</AcCard>
	);
};

export default memo(AcKpiCard);
