// Imports => React
import React, { useRef, useMemo, memo } from 'react';
import clsx from 'clsx';

// Imports => Atoms
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';

const _CLASSES = {
  MAIN: 'ac-button',
  SIZES: {
    DEFAULT: 'ac-button--regular',
    SMALL: 'ac-button--small',
    NONE: '',
  },
  THEME: {
    DEFAULT: 'ac-button--pitch',
    NONE: '',
    OUTLINE: 'ac-button--outline',
    PITCH: {
      MAIN: 'ac-button--pitch',
      DEFAULT: '',
      OUTLINE: 'ac-button--pitch-outline',
      TRANSPARENT: 'ac-button--pitch-transparent',
    },
    WHITE: {
      MAIN: 'ac-button--white',
      DEFAULT: 'ac-button--white',
      OUTLINE: 'ac-button--white-outline',
      TRANSPARENT: 'ac-button--white-transparent',
    },
    SUBTLE: {
      MAIN: 'ac-button--subtle',
      DEFAULT: 'ac-button--subtle',
      OUTLINE: 'ac-button--subtle-outline',
      TRANSPARENT: 'ac-button--subtle-transparent',
    },
    ALPHA: {
      MAIN: 'ac-button--alpha',
      DEFAULT: 'ac-button--alpha',
      OUTLINE: 'ac-button--alpha-outline',
      TRANSPARENT: 'ac-button--alpha-transparent',
    },
    OMEGA: {
      MAIN: 'ac-button--omega',
      DEFAULT: 'ac-button--omega',
      OUTLINE: 'ac-button--omega-outline',
      TRANSPARENT: 'ac-button--omega-transparent',
      TEXT: 'ac-button--omega-text',
    },
  },
  BLOCK: 'ac-button--block',
  DISABLED: 'ac-button--disabled',
  LOADING: 'ac-button--loading',
  LOADER: 'ac-button__loader',
};

// Component
const AcButton = ({
  children,
  tag = 'button',
  type = 'button',
  theme = 'default',
  variant = 'default',
  size = 'default',
  disabled = false,
  block = false,
  loading = false,
  callback,
  className,
  ...rest
}) => {
  const $element = useRef();

  const handleClick = (event) => {
    if (!disabled && callback) callback(event, $element.current);
  };

  const getLoaderClassNames = useMemo(() => {
    return clsx(_CLASSES.LOADER);
  }, []);

  const getThemeAndStyleClassNames = (theme, variant) => {
    let className = false;

    const match = {
      theme: theme
        ? theme.replace('-', '').replace('_', '').trim().toUpperCase()
        : false,
      variant: variant
        ? variant.replace('-', '').replace('_', '').trim().toUpperCase()
        : false,
    };

    if (match.theme && match.variant) {
      className = _CLASSES.THEME[match.theme][match.variant];
    } else if (match.theme) {
      className = _CLASSES.THEME[match.theme].MAIN;
    } else if (match.variant) {
      className = _CLASSES.THEME.OUTLINE;
    }

    return className;
  };

  const getStyleClassNames = useMemo(() => {
    return clsx(
      _CLASSES.MAIN,
      getThemeAndStyleClassNames(theme, variant),
      size && _CLASSES.SIZES[size.toUpperCase()],
      disabled && _CLASSES.DISABLED,
      block && _CLASSES.BLOCK,
      loading && _CLASSES.LOADING,
      className
    );
  }, [size, disabled, block, theme, variant, loading, className]);

  const Tag = tag;

  return (
    <Tag
      ref={$element}
      type={type}
      aria-roledescription={'button'}
      disabled={disabled}
      onClick={handleClick}
      className={getStyleClassNames}
      {...rest}
    >
      <div className={getLoaderClassNames} />
      {children || 'Button'}
      <AcRipple size={'small'} simple />
    </Tag>
  );
};

export default memo(AcButton);
