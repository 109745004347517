import { useCallback, useState, useMemo } from 'react';

/**
 * React custom hook.
 * This hook manages click events on the document and the target component.
 * When clicked outside the target, the visible state changes.
 * @param {*} isVisible
 */
export const useCluster = (cluster) => {
  const [counter, setCounter] = useState(0);

  const numMarkers = useMemo(() => {
    if (!cluster) return 0;
    return cluster.getSize();
  }, [cluster]);

  const currentMarker = useMemo(() => {
    if (!cluster) return null;
    const markers = cluster.getMarkers();
    if (markers && markers.length) {
      return markers[counter];
    }
    return null;
  }, [counter, cluster]);

  const prevMarker = useCallback(() => {
    setCounter((c) => (c < 1 ? numMarkers - 1 : c - 1));
  }, [cluster, setCounter]);
  const nextMarker = useCallback(() => {
    setCounter((c) => (c < numMarkers - 1 ? c + 1 : 0));
  }, [cluster, setCounter]);

  return {
    currentMarkerNumber: counter,
    totalMarkers: numMarkers,
    currentMarker,
    nextMarker,
    prevMarker,
  };
};
