import React, { useMemo, useEffect } from 'react';
import { ClusterInfoWindow } from './components/ac-info-window-cluster';
import { InfoWindow } from './components/ac-info-window';
import { useClickOutside } from './hooks/use-click-outside';

const Markers = {
  marker: InfoWindow,
  cluster: ClusterInfoWindow,
};

const noop = () => <></>;
/**
 *
 */
export const AcInfoWindow = ({
  getItem,
  activeMarker,
  onHideInfoWindow,
  itemRenderer: ItemRenderer,
}) => {
  const { setRef, visible, setVisible } = useClickOutside(false);
  const { type, marker } = activeMarker || {};

  const MarkerInfoWindow = useMemo(() => {
    return type ? Markers[type] : noop;
  }, [type]);

  useEffect(() => {
    setVisible(marker ? true : false);
  }, [marker]);

  useEffect(() => {
    if (!visible) {
      onHideInfoWindow();
    }
  }, [visible]);

  return (
    <div
      ref={setRef}
      className={`ac-info-window-content ${visible ? 'active' : ''}`}
    >
      <MarkerInfoWindow closeable marker={marker} setVisible={setVisible}>
        {(item) => {
          return item ? <ItemRenderer item={getItem(item)} /> : null;
        }}
      </MarkerInfoWindow>
    </div>
  );
};
