import React, { useEffect, forwardRef, useMemo, memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
  AcIsSet,
  AcIsUndefined,
  AcGetEquipmentIcon,
  AcFormatInternalURI,
} from '@utils';

const _CLASSES = {
  MAIN: 'ac-dashboard-map-widget-item',
  CONTENT: 'ac-dashboard-map-widget-item__content',
  DETAILS: 'ac-dashboard-map-widget-item__details',
  PROPERTIES: 'ac-dashboard-map-widget-item__properties',
  NAME: 'ac-dashboard-map-widget-item__name',
  COMPANY: 'ac-dashboard-map-widget-item__company',
  TYPE: 'ac-dashboard-map-widget-item__type',
  PROGRESS: {
    MAIN: 'ac-dashboard-map-widget-item__progress',
    BAR: 'ac-dashboard-map-widget-item__progress__bar',
    TRACKER: 'ac-dashboard-map-widget-item__progress__tracker',
    VALUE: 'ac-dashboard-map-widget-item__progress__value',
  },
  STATUS: {
    MAIN: 'ac-dashboard-map-widget-item__status',
    ONLINE: 'ac-dashboard-map-widget-item__status--online',
    OFFLINE: 'ac-dashboard-map-widget-item__status--offline',
  },
};

const AcDashboardMapWidgetItem = forwardRef(
  (
    {
      id,
      name,
      company,
      group,
      type,
      progress = null,
      is_online,
      mouseover,
      mouseleave,
      software,
    },
    ref
  ) => {
    const navigate = useNavigate();

    const handleClick = (event) => {
      const link = {
        id,
        name: null,
        entity: group,
        equipment_group: group,
      };

      const route = AcFormatInternalURI(link, name);

      if (AcIsSet(route)) navigate(route);
    };

    useEffect(() => {
      //   console.log('software', software);
    }, [software]);

    const handleMouseOver = (event) => {
      if (mouseover) mouseover(event, { id });
    };

    const handleMouseLeave = (event) => {
      if (mouseleave) mouseleave(event, { id });
    };

    const getDetailRoute = useMemo(() => {
      const link = {
        id,
        name: null,
        entity: group,
        equipment_group: group,
      };

      const route = AcFormatInternalURI(link, name);

      return route;
    }, [id, group, name]);

    const getProgressValueClassNames = useMemo(() => {
      return clsx(_CLASSES.PROGRESS.VALUE);
    }, []);

    const getProgressTrackerClassNames = useMemo(() => {
      return clsx(_CLASSES.PROGRESS.TRACKER);
    }, []);

    const getProgressBarClassNames = useMemo(() => {
      return clsx(_CLASSES.PROGRESS.BAR);
    }, []);

    const getProgressClassNames = useMemo(() => {
      return clsx(_CLASSES.PROGRESS.MAIN);
    }, []);

    const getPropertiesClassNames = useMemo(() => {
      return clsx(_CLASSES.PROPERTIES);
    }, []);

    const getOnlineClassNames = useMemo(() => {
      return clsx(
        _CLASSES.STATUS.MAIN,
        is_online && _CLASSES.STATUS.ONLINE,
        !is_online && _CLASSES.STATUS.OFFLINE
      );
    }, [is_online]);

    const getTypeClassNames = useMemo(() => {
      return clsx(_CLASSES.TYPE);
    }, []);

    const getCompanyClassNames = useMemo(() => {
      return clsx(_CLASSES.COMPANY);
    }, []);

    const getNameClassNames = useMemo(() => {
      return clsx(_CLASSES.NAME);
    }, []);

    const getDetailsClassNames = useMemo(() => {
      return clsx(_CLASSES.DETAILS);
    }, []);

    const getContentClassNames = useMemo(() => {
      return clsx(_CLASSES.CONTENT);
    }, []);

    const getMainClassNames = useMemo(() => {
      return clsx(_CLASSES.MAIN);
    }, []);

    const renderProgress = useMemo(() => {
      if (!AcIsSet(progress)) return null;

      const { value, total } = progress;

      let _value = 0;
      let _total = 0;
      let width = 0;

      if (AcIsSet(value) && AcIsSet(total)) {
        _value = value;
        _total = total;
        width = (100 * value) / total;
      }

      /*
      // not used atm since the data isn't available
      <div className={getProgressBarClassNames}>
        <div
          className={getProgressTrackerClassNames}
          style={{ width: `${width}%` }}
        />
      </div>
      */

      return (
        <div className={getProgressClassNames}>
          <div
            className={getProgressValueClassNames}
            dangerouslySetInnerHTML={{
              __html: `${_value} piles driven`,
            }}
          />
        </div>
      );
    }, [progress]);

    const renderOnlineStatus = useMemo(() => {
      if (AcIsUndefined(is_online)) return null;
      if (!AcIsSet(type) || !AcIsSet(type.group)) return null;
      if (
        AcIsSet(type) &&
        AcIsSet(type.group) &&
        type.group !== 'Control units'
      )
        return null;

      return (
        <div
          className={getOnlineClassNames}
          dangerouslySetInnerHTML={{
            __html: is_online ? 'ON' : 'OFF',
          }}
        />
      );
    }, [type, is_online]);

    const renderType = useMemo(() => {
      if (!AcIsSet(type)) return null;

      return (
        <div
          className={getTypeClassNames}
          dangerouslySetInnerHTML={{
            __html: type && type.name,
          }}
        />
      );
    }, [type]);

    const renderCompany = useMemo(() => {
      if (!AcIsSet(company)) return null;

      return (
        <div
          className={getCompanyClassNames}
          dangerouslySetInnerHTML={{
            __html: company && company.name,
          }}
        />
      );
    }, [company]);

    const renderName = useMemo(() => {
      if (!AcIsSet(name)) return null;

      let pre = '';

      if (AcIsSet(type) && AcIsSet(type.group)) {
        const icon = AcGetEquipmentIcon(type.group);
        if (icon) pre = `<i class="ac-icon ac-icon--${icon}"></i>`;
      }

      return (
        <div
          className={getNameClassNames}
          dangerouslySetInnerHTML={{
            __html: `${pre}${name}`,
          }}
        />
      );
    }, [name, type]);

    return (
      <Link
        to={getDetailRoute}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        ref={ref}
        id={id}
        className={getMainClassNames}
      >
        <div className={getContentClassNames}>
          <div className={getDetailsClassNames}>
            {renderName}
            {renderCompany}
          </div>

          {AcIsSet(type) && AcIsSet(type.name) && (
            <div className={getPropertiesClassNames}>{renderType}</div>
          )}

          {AcIsSet(software) && (
            <div className={getPropertiesClassNames}>
              <div className={getNameClassNames}>{software}</div>
            </div>
          )}

          {renderOnlineStatus}
        </div>

        {progress && renderProgress}
      </Link>
    );
  }
);

export default memo(AcDashboardMapWidgetItem);
