// Imports => React
import React, { memo, useMemo } from 'react';
import clsx from 'clsx';

// Imports => Constants
import { VISUALS } from '@constants';

const _CLASSES = {
  MAIN: 'ac-loader',
  WRP: {
    MAIN: 'ac-loader-wrp',
    COVER: 'ac-loader-wrp--cover',
    INLINE: 'ac-loader-wrp--inline',
    TRANSPARENT: 'ac-loader-wrp--transparent',
    PITCH: 'ac-loader-wrp--pitch',
    LOADING: 'ac-loader-wrp--loading',
  },
  VISUAL: 'ac-loader__visual',
};

// Component
const AcLoader = ({
  Visual = VISUALS.LOADER,
  loading = false,
  cover = false,
  inline = false,
  transparent = false,
  pitch = false,
  wrpClassName,
  className,
}) => {
  const getLoaderVisualClassNames = useMemo(() => {
    return clsx(_CLASSES.VISUAL);
  });

  const getLoaderClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN, className && className);
  });

  const getLoaderWrpClassNames = useMemo(() => {
    return clsx(
      _CLASSES.WRP.MAIN,
      loading && _CLASSES.WRP.LOADING,
      cover && _CLASSES.WRP.COVER,
      inline && _CLASSES.WRP.INLINE,
      transparent && _CLASSES.WRP.TRANSPARENT,
      pitch && _CLASSES.WRP.PITCH,
      wrpClassName && wrpClassName
    );
  }, [loading, cover, inline, transparent, pitch, wrpClassName]);

  return (
    <div className={getLoaderWrpClassNames}>
      <div className={getLoaderClassNames}>
        <div className={getLoaderVisualClassNames}>
          <Visual alt={'loader-visual'} />
        </div>
      </div>
    </div>
  );
};

export default memo(AcLoader);
