// Imports => React
import React, { forwardRef, useCallback, useMemo } from 'react';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-card',
  DEFAULT: 'ac-card--default',
  TRANSPARENT: 'ac-card--transparent',
  PLACEHOLDER: 'ac-card--placeholder',
  PATTERN: 'ac-card--pattern',
  WHITE: 'ac-card--white',
  LIGHT: 'ac-card--light',
  SUBTLE: 'ac-card--subtle',
  DARK: 'ac-card--dark',
  PITCH: 'ac-card--pitch',
  BLACK: 'ac-card--black',
  ALPHA: 'ac-card--alpha',
  OMEGA: 'ac-card--omega',
  SQUARE: 'ac-card--square',
  FLAT: 'ac-card--flat',
  RAISED: 'ac-card--raised',
  DENSE: 'ac-card--dense',
  NARROW: 'ac-card--narrow',
  FULLHEIGHT: 'ac-card--fullheight',
  NO_ANIMATION: 'ac-card--no-animation',
  ALIGNMENT: {
    LEFT: 'ac-card--align-left',
    CENTER: 'ac-card--align-center',
    RIGHT: 'ac-card--align-right',
  },
};

// Component
const AcCard = forwardRef(
  (
    {
      id,
      theme = 'default',
      hoverAnimation = true,
      square = false,
      flat = true,
      raised = false,
      dense = false,
      narrow = false,
      fullheight = false,
      alignment,
      width,
      height,
      className,
      children,
      callback,
      ...rest
    },
    ref
  ) => {
    const handleCallback = useCallback(
      (event) => {
        if (callback) callback(event);
      },
      [callback]
    );

    const getStyleClassNames = useMemo(() => {
      return clsx(
        _CLASSES.MAIN,
        theme && _CLASSES[theme.toUpperCase()],
        alignment && _CLASSES.ALIGNMENT[alignment.toUpperCase()],
        square && _CLASSES.SQUARE,
        dense && _CLASSES.DENSE,
        narrow && _CLASSES.NARROW,
        flat && _CLASSES.FLAT,
        raised && _CLASSES.RAISED,
        fullheight && _CLASSES.FULLHEIGHT,
        !hoverAnimation && _CLASSES.NO_ANIMATION,
        className
      );
    }, [
      theme,
      alignment,
      square,
      dense,
      narrow,
      flat,
      raised,
      fullheight,
      hoverAnimation,
      className,
    ]);

    const getInlineStyle = useMemo(() => {
      return {
        width: width ? `${width / 10}rem` : undefined,
        height: height ? `${height / 10}rem` : undefined,
      };
    }, [width, height]);

    return (
      <article
        id={id}
        className={getStyleClassNames}
        style={getInlineStyle}
        onClick={handleCallback}
        ref={ref}
        {...rest}
      >
        {children}
      </article>
    );
  }
);

export default React.memo(AcCard);
