import React, { useMemo, memo } from 'react';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => Utilities
import { AcUUID } from '@utils';

// Imports => Atoms
const AcRichContent = loadable(() =>
	import('@atoms/ac-rich-content/ac-rich-content.web')
);

const _CLASSES = {
	MAIN: 'ac-alert',
	THEMES: {
		DEFAULT: 'ac-alert--default',
		ALPHA: 'ac-alert--alpha',
		ALPHA_LIGHT: 'ac-alert--alpha-light',
		OMEGA: 'ac-alert--omega',
		OMEGA_LIGHT: 'ac-alert--omega-light',
		WHITE: 'ac-alert--white',
		LIGHT: 'ac-alert--light',
		SUBTLE: 'ac-alert--subtle',
		MEDIUM: 'ac-alert--medium',
		DARK: 'ac-alert--dark',
		PITCH: 'ac-alert--pitch',
		BLACK: 'ac-alert--black',
		SUCCESS: 'ac-alert--success',
		ERROR: 'ac-alert--error',
		WARNING: 'ac-alert--warning',
		INFO: 'ac-alert--info',
		INFO_LIGHT: 'ac-alert--info-light',
	},
};

const AcAlert = memo(
	({ id = AcUUID(), theme = 'default', content, className }) => {
		const getMainClassNames = useMemo(() => {
			return clsx(
				_CLASSES.MAIN,
				theme && _CLASSES.THEMES[theme.toUpperCase().replace(/-/g, '_')],
				className
			);
		}, [theme, className]);

		return (
			<div id={id} className={getMainClassNames} role={'alert'}>
				<AcRichContent content={content} />
			</div>
		);
	}
);

export { AcAlert };
export default AcAlert;
