import React from 'react';
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';
import { ICONS, THEMES, SIZES } from '@constants';

export const InfoWindow = ({
  marker,
  setVisible,
  header,
  closeable,
  children,
}) => {
  return (
    <div className={'ac-info-window-anchor'}>
      <div className={'ac-info-window-bubble'}>
        <div className={'ac-info-window-bubble--header'}>
          {header}
          {closeable && (
            <div
              className="ac-info-window-bubble--header__close"
              style={{ position: 'relative' }}
              onClick={(_) => setVisible(false)}
            >
              <AcIcon
                icon={ICONS.CLOSE}
                className={'ac-info-window-bubble--header__icon close'}
              />
              <AcRipple theme={THEMES.OMEGA} size={SIZES.SMALL} />
            </div>
          )}
        </div>

        <div className={'ac-info-window-bubble--content'}>
          {children && children(marker)}
        </div>
      </div>
    </div>
  );
};
