import React, { useState, useCallback, useEffect, useMemo } from 'react';
import AcDropzone from '@atoms/ac-dropzone/ac-dropzone.web';
import AcCard from '@atoms/ac-card/ac-card.web';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import { THEMES, TYPES, VARIANTS, SIZES } from '@constants';
import AcButton from '@atoms/ac-button/ac-button.web';
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';

const handleEvent = (event) => {
  if (event && event.preventDefault) event.preventDefault();
  if (event && event.persist) event.persist();
};

export const AcCompanyLogo = ({ add, remove, company, loading }) => {
  const [logoFile, setLogoFile] = useState();
  const [hasLogo, setHasLogo] = useState();
  const { logo } = company || {};
  useEffect(() => {
    setHasLogo(logo !== null);
  }, [logo]);

  const addLogo = useCallback(
    (_, data) => {
      const formData = new FormData();
      formData.append('logo', data.get('header'));
      add(formData);
    },
    [logoFile]
  );
  const removeLogo = useCallback(
    (event) => {
      handleEvent(event);
      if (remove && hasLogo && logo) {
        remove(logo.file_name);
        setLogoFile(null);
      }
    },
    [hasLogo, logo]
  );

  const buttonOptions = useMemo(() => {
    return {
      type: TYPES.BUTTON,
      theme: THEMES.OMEGA,
      variant: VARIANTS.TEXT,
      size: SIZES.MEDIUM,
      title: '',
      callback: removeLogo,
    };
  });

  return (
    <AcCard>
      <AcHeading rank={6}>Company logo</AcHeading>
      <div className="ac-details-card__value h-margin-bottom-25">
        Your logo will be shown on the piling reports.
      </div>
      <AcDropzone
        {...(logo && { value: logo.url })}
        accept={'.png, .jpg, .jpeg'}
        callback={addLogo}
        contain
        subtext={`JPG, JPEG, PNG`}
        loading={loading}
      />

      <AcContainer fluid>
        <AcRow
          className={'h-margin-y-0 h-margin-top-35 justify-content-md-end'}
        >
          {logoFile && (
            <AcColumn xs={3} sm={3}>
              <AcButton {...buttonOptions} callback={addLogo}>
                <span>Add logo</span>
              </AcButton>
            </AcColumn>
          )}
          {hasLogo && (
            <AcColumn xs={3} sm={3}>
              <div className={'h-margin-top-15 h-flex-h-align-end'}>
                <AcButton {...buttonOptions}>
                  <span>Remove</span>
                </AcButton>
              </div>
            </AcColumn>
          )}
        </AcRow>
      </AcContainer>
    </AcCard>
  );
};
