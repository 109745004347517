import React from 'react';

const AcDropDown = ({ onClick, results, render }) => {
  return (
    <div className={'ac-search-dropdown'}>
      {results &&
        results.map((result, idx) => {
          return (
            <div
              tabIndex={2}
              onClick={() => onClick(result)}
              key={idx}
              className={'ac-search-dropdown--item'}
            >
              {render(result)}
            </div>
          );
        })}
    </div>
  );
};

export default AcDropDown