import React, { useCallback, useMemo, memo } from 'react';
import clsx from 'clsx';

// Imports => Constants
import { KEYS, THEMES } from '@constants';

// Imports => Utilities
import {
  AcUUID,
  AcIsSet,
  AcIsUndefined,
  AcIsNumeric,
  AcFormatSecondsToHms,
} from '@utils';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcCard from '@atoms/ac-card/ac-card.web';
import AcHeading from '@atoms/ac-heading/ac-heading.web';

const _CLASSES = {
  MAIN: 'ac-kpi-card ac-kpi-card--group',
  CONTENT: 'ac-kpi-card__content',
  TITLE: 'ac-kpi-card__title',
  LABEL: 'ac-kpi-card__label',
  VALUE: 'ac-kpi-card__value',
  SUBTEXT: 'ac-kpi-card__subtext',
  PROGRESS: {
    MAIN: 'ac-kpi-card__progress',
    TRACKER: 'ac-kpi-card__progress-tracker',
  },
};

const AcKpiGroupCard = ({ title, collection }) => {
  const getProgressTrackerClassNames = useMemo(() => {
    return clsx(_CLASSES.PROGRESS.TRACKER);
  }, []);

  const getProgressClassNames = useMemo(() => {
    return clsx(_CLASSES.PROGRESS.MAIN);
  }, []);

  const getValueClassNames = useMemo(() => {
    return clsx(_CLASSES.VALUE);
  }, []);

  const getLabelClassNames = useMemo(() => {
    return clsx(_CLASSES.LABEL);
  }, []);

  const getTitleClassNames = useMemo(() => {
    return clsx(_CLASSES.TITLE);
  }, []);

  const getContentClassNames = useMemo(() => {
    return clsx(_CLASSES.CONTENT);
  });

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  }, []);

  const formatValue = (value, type, total) => {
    let result = value;

    if (!AcIsSet(value)) return '-';

    switch (type) {
      case KEYS.DURATION:
        if (!isNaN(parseFloat(value))) result = AcFormatSecondsToHms(value);
        else result = value;
        break;

      case KEYS.JOULE:
        result =
          value > 100000 ? `${Math.round(value / 1000)} mJ` : `${value} kJ`;
        break;

      case KEYS.BAR:
        result = `${Math.round(value / 100000)} bar`;
        break;

      case KEYS.PROGRESS:
        result = `${value}<sub>/${total}</sub>`;
        break;

      default:
    }

    return result;
  };

  const renderProgress = (value, total) => {
    if (!AcIsSet(value)) return null;
    if (!AcIsSet(total)) return null;

    const width = (100 * value) / total;

    return (
      <div className={getProgressClassNames}>
        <div
          className={getProgressTrackerClassNames}
          style={{ width: `${width}%` }}
        />
      </div>
    );
  };

  const colsize = useMemo(() => {
    const len = collection.length;
    return 12 / len;
  }, [collection]);

  const renderItem = ({ label, value, total, type }) => {
    return (
      <AcColumn xxs={12} xs={6} sm={colsize} key={AcUUID()}>
        {label && (
          <span
            className={getLabelClassNames}
            dangerouslySetInnerHTML={{
              __html: label,
            }}
          />
        )}
        {!AcIsUndefined(value) && (
          <div className={getValueClassNames}>
            <span
              dangerouslySetInnerHTML={{
                __html: formatValue(value, type),
              }}
              rel={formatValue(value, type)}
            />
            {type === KEYS.PROGRESS && renderProgress}
          </div>
        )}
      </AcColumn>
    );
  };

  const renderItems = useMemo(() => {
    const len = collection.length;
    let n = 0;
    let result = [];

    for (n; n < len; n++) {
      const item = collection[n];

      const object = renderItem(item);

      result.push(object);
    }

    return result;
  }, [collection]);

  return (
    <AcCard theme={THEMES.PITCH} flat className={getMainClassNames}>
      <div className={getContentClassNames}>
        {title && (
          <AcHeading rank={5} className={getTitleClassNames}>
            {title}
          </AcHeading>
        )}
        <AcRow>{renderItems}</AcRow>
      </div>
    </AcCard>
  );
};

export default memo(AcKpiGroupCard);
