import React, { useCallback, useMemo, memo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Fade } from 'react-awesome-reveal';
import clsx from 'clsx';

import config from '@config';

// Imports => Constants
import { ICONS, KEYS, SIZES, THEMES, TYPES, VARIANTS } from '@constants';

// Imports => Utilities
import { AcIsSet, AcGetHumanizedBytesDisplay } from '@utils';

// Imports => Atoms
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';
import AcImage from '@atoms/ac-image/ac-image.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
  MAIN: 'ac-dropzone',
  DRAGGING: 'ac-dropzone--dragging',
  INSTRUCTIONS: 'ac-dropzone__instructions',
  CONTAIN: 'ac-image--background-image-contain',
};

const AcDropzone = ({ id, value, callback, loading, contain }) => {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files

    if (AcIsSet(acceptedFiles) && AcIsSet(acceptedFiles[0])) {
      const data = new FormData();
      data.append('header', acceptedFiles[0]);
      data.append('_method', 'PUT');
      if (callback) callback(id, data, { upload: true });
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: config.maxFileSize,
    accept: {
      'image/*': ['jpg', 'jpeg', 'png', 'webp'],
    },
    disabled: AcIsSet(value),
  });

  const getInstructionsClassNames = useMemo(() => {
    return clsx(_CLASSES.INSTRUCTIONS);
  }, []);

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN, isDragActive && _CLASSES.DRAGGING);
  }, [isDragActive]);

  const renderFileError = useMemo(() => {
    if (!fileRejections || !fileRejections.length) return null;

    let error = null;
    let code = null;

    if (fileRejections && fileRejections.length > 1) code = 'too-many-files';
    else if (fileRejections[0] && fileRejections[0].errors) {
      code = fileRejections[0].errors[0].code;
    }

    switch (code) {
      case 'too-many-files':
        error = 'Please upload only 1 file.';
        break;

      case 'file-invalid-type':
        error = 'Invalid file type. Only JPG, JPEG, PNG and WEBP are allowed.';
        break;

      case 'file-too-large':
        error = 'File is too big.';
        break;

      default:
        error = `Something has gone wrong. Please try again.`;
    }

    return (
      <div className={'ac-dropzone__error'}>
        <AcIcon icon={ICONS.PROGRESS_ALERT} /> <span>{error}</span>
      </div>
    );
    // return <div>{}</div>;
  }, [fileRejections]);

  const renderInstructions = useMemo(() => {
    if (isDragActive) {
      return (
        <div className={getInstructionsClassNames}>
          <h4>Drop your image here</h4>
        </div>
      );
    }

    return (
      <div className={getInstructionsClassNames}>
        <h4>Drag and drop an image here</h4>
        <p>
          or <u>click</u> to browse
        </p>
        <p className={'subtext'}>JPG, JPEG, PNG or WEBP</p>
        <p className={'subtext'}>
          The image should be 400x300 or more for optimal results.
        </p>
        <p className={'subtext'}>Max. 4 MB</p>
      </div>
    );
  }, [isDragActive]);

  const renderImage = useMemo(() => {
    if (!AcIsSet(value)) return null;

    return (
      <AcImage
        className={contain && _CLASSES.CONTAIN}
        source={value}
        type={TYPES.BACKGROUND}
      />
    );
  }, [value]);

  return (
    <div className={getMainClassNames} {...getRootProps()}>
      <input {...getInputProps()} />

      {renderInstructions}

      {renderFileError}

      {renderImage}

      <AcRipple theme={THEMES.PITCH} size={SIZES.LARGE} simple />

      <AcLoader loading={loading} cover />
    </div>
  );
};

export default memo(AcDropzone);
