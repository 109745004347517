import React, { useMemo } from 'react';
import { KEYS } from '@constants';
import clsx from 'clsx';
// Imports => Utilities
import { AcIsSet, AcFormatInternalURI, AcGetEquipmentIcon } from '@utils';
import { Link } from 'react-router-dom';

const _CLASSES = {
  MAIN: 'ac-dashboard-map-widget-item',
  CONTENT: 'ac-dashboard-map-widget-item__content',
  DETAILS: 'ac-dashboard-map-widget-item__details',
  PROPERTIES: 'ac-dashboard-map-widget-item__properties',
  NAME: 'ac-dashboard-map-widget-item__name',
  COMPANY: 'ac-dashboard-map-widget-item__company',
  TYPE: 'ac-dashboard-map-widget-item__type',
  PROGRESS: {
    MAIN: 'ac-dashboard-map-widget-item__progress',
    BAR: 'ac-dashboard-map-widget-item__progress__bar',
    TRACKER: 'ac-dashboard-map-widget-item__progress__tracker',
    VALUE: 'ac-dashboard-map-widget-item__progress__value',
  },
  STATUS: {
    MAIN: 'ac-dashboard-map-widget-item__status',
    ONLINE: 'ac-dashboard-map-widget-item__status--online',
    OFFLINE: 'ac-dashboard-map-widget-item__status--offline',
  },
};
const createEquipmentIcon = ({ equipment_type: type }) => {
  if (AcIsSet(type) && AcIsSet(type.group)) {
    return AcGetEquipmentIcon(type.group);
  }
};

const createLink = ({ id, name, equipment_type: type, object_no }) => {
  if (AcIsSet(name)) name = name;
  else if (AcIsSet(object_no)) name = object_no;

  let group =
    (AcIsSet(type) && AcIsSet(type.group) && type.group) || KEYS.PROJECTS;
  type = !AcIsSet(type) ? KEYS.PROJECTS : type;

  const link = {
    id,
    name: null,
    entity: group,
    equipment_group: group,
  };
  return AcFormatInternalURI(link, name);
};

const EquipmentItemRenderer = ({ item }) => {
  const link = useMemo(() => createLink(item), [item]);
  const icon = createEquipmentIcon(item);

  let software_version = null;

  if (item?.software?.c36_operation_unit) {
    software_version = item?.software?.c36_operation_unit;
  } else if (item?.software?.CB) {
    software_version = item?.software?.CB;
  }

  return (
    <Link
      to={link}
      id={item.id}
      className={clsx(_CLASSES.MAIN)}
      style={{ padding: '0.5rem 0', height: '100%' }}
    >
      <div
        className={clsx(_CLASSES.NAME)}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '0.6rem 0',
        }}
      >
        {icon && (
          <i style={{ top: 0 }} className={`ac-icon ac-icon--${icon}`}></i>
        )}
        {item.object_no}
        <div
          className={clsx(_CLASSES.PROPERTIES)}
          style={{ marginLeft: '12px' }}
        >
          <div className={clsx(_CLASSES.TYPE)}>
            {item.equipment_type && item.equipment_type.name}
          </div>
        </div>
      </div>
      <div className={clsx(_CLASSES.COMPANY)}>
        {item.company && item.company.name}
      </div>
      <div
        className={clsx(_CLASSES.PROPERTIES)}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '0.6rem 0',
          marginTop: '12px',
        }}
      >
        <div style={{ fontSize: '1.3rem' }}>{software_version}</div>
        <div
          style={{ marginLeft: '12px' }}
          className={clsx(
            _CLASSES.STATUS.MAIN,
            item.is_online && _CLASSES.STATUS.ONLINE,
            !item.is_online && _CLASSES.STATUS.OFFLINE
          )}
        >
          {item.is_online ? 'ON' : 'OFF'}
        </div>
      </div>
    </Link>
  );
};

const ProjectItemRenderer = ({ item }) => {
  const link = useMemo(() => createLink(item), [item]);
  return (
    <Link
      to={link}
      id={item.id}
      className={clsx(_CLASSES.MAIN)}
      style={{ padding: '0.5rem 0', height: '100%' }}
    >
      <div
        className={clsx(_CLASSES.NAME)}
        style={{
          padding: '0.6rem 0',
          width: '240px',
        }}
      >
        {item.name}
        <div
          className={clsx(_CLASSES.PROPERTIES)}
          style={{ marginLeft: '12px' }}
        >
          <div className={clsx(_CLASSES.TYPE)}></div>
        </div>
      </div>
      <div className={clsx(_CLASSES.COMPANY)}>
        {item && item.company && item.company.name}
      </div>

      <div
        className={clsx(_CLASSES.NAME)}
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '0.8rem 0',
        }}
      >
        <div style={{ flex: '0 0 100px', color: '#fff', fontSize: '1.2rem' }}>
          {item.stats && item.stats.piles_driven && item.stats.pile_count
            ? `${item.stats.piles_driven}/${item.stats.pile_count} piles driven`
            : `0 piles driven`}
        </div>
      </div>
    </Link>
  );
};

export const itemRendererFactory = (entity) => {
  switch (entity) {
    case KEYS.PROJECTS:
      return ProjectItemRenderer;
    default:
      return EquipmentItemRenderer;
  }
};
