// Imports => React
import React, { useRef, useEffect, useMemo, memo } from 'react';
import clsx from 'clsx';

// Imports => Constants
import { TYPES } from '@constants';

const _CLASSES = {
  MAIN: 'ac-table-checkbox',
  DISABLED: 'ac-table-checkbox--disabled',
  BEFORE: 'ac-table-checkbox--before',
  AFTER: 'ac-table-checkbox--after',
  INPUT: {
    MAIN: 'ac-table-checkbox__input',
    DISABLED: 'ac-table-checkbox__input--disabled',
  },
  DISPLAY: {
    MAIN: 'ac-table-checkbox__display',
    DISABLED: 'ac-table-checkbox__display--disabled',
  },
  LABEL: {
    MAIN: 'ac-table-checkbox__label',
    DISABLED: 'ac-table-checkbox__label--disabled',
    BEFORE: 'ac-table-checkbox__label--before',
    AFTER: 'ac-table-checkbox__label--after',
  },
};

// Component
const AcTableCheckbox = ({
  children,
  name,
  id,
  value,
  position = 'after',
  checked = false,
  required = true,
  type = TYPES.CHECKBOX,
  validation,
  callback,
  disabled,
}) => {
  const $label = useRef(null);
  const $input = useRef(null);

  useEffect(() => {
    if (validation) validation(name, value, required, type, checked);
  }, [checked]);

  const handleChange = (event) => {
    if (event && event.persist) event.persist();
    const { checked } = $input.current;
    const selected = !checked;

    if (callback) callback(event, name, value, type, selected);
    if (validation) validation(name, value, required, type, selected);
  };

  const getDisplayClassNames = useMemo(() => {
    return clsx(_CLASSES.DISPLAY.MAIN, disabled && _CLASSES.DISPLAY.DISABLED);
  }, [disabled]);

  const getInputClassNames = useMemo(() => {
    return clsx(_CLASSES.INPUT.MAIN, disabled && _CLASSES.INPUT.DISABLED);
  }, [disabled]);

  const getLabelClassNames = useMemo(() => {
    return clsx(
      _CLASSES.LABEL.MAIN,
      disabled && _CLASSES.LABEL.DISABLED
      //   position && _CLASSES.LABEL[position.toUpperCase()]
    );
  }, [disabled, position]);

  const getMainClassNames = useMemo(() => {
    return clsx(
      _CLASSES.MAIN,
      disabled && _CLASSES.DISABLED,
      position && _CLASSES[position.toUpperCase()]
    );
  }, [disabled, position]);

  return (
    <div
      htmlFor={id}
      className={getMainClassNames}
      onClick={handleChange}
      ref={$label}
    >
      <input
        type={'checkbox'}
        id={id}
        value={value}
        name={name}
        disabled={disabled}
        checked={checked}
        onChange={handleChange}
        className={getInputClassNames}
        ref={$input}
      />
      <div className={getLabelClassNames}>
        <div
          className={getDisplayClassNames}
          role={'button'}
          data-active={checked}
        />
      </div>
    </div>
  );
};

export default memo(AcTableCheckbox);
