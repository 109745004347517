// Imports => React
import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router-dom';

// Imports => Constants
import { REDIRECT_ROUTE } from '@constants';

const AcPrivateRoute = ({
	store,
	forbidden,
	component: Component,
	authorized,
	...rest
}) => {
	const location = useLocation();

	const renderRoute = useMemo(() => {
		if (!forbidden || (forbidden && authorized)) {
			return <Component {...rest} authorized={authorized} />;
		} else if (
			!authorized &&
			location.pathname !== REDIRECT_ROUTE.path &&
			window.location.pathname !== REDIRECT_ROUTE.path
		) {
			return (
				<Navigate
					from={location.pathname}
					to={{
						pathname: REDIRECT_ROUTE.path,
						state: { from: location },
					}}
					state={{ from: location }}
					replace
				/>
			);
		} else {
			return null;
		}
	}, [store, forbidden, authorized, Component, rest]);

	return renderRoute;
};

export default withStore(observer(AcPrivateRoute));
