import React, { useMemo, useRef, memo, useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-progress-circular',
};

const AcProgressCircular = ({ className, timer, loop = false, timerStart, progress, onComplete }) => {
  const intervalTimer = useRef(null)
  const timeoutTimer = useRef(null)

  const [label, setLabel] = useState(0)
  const [_timerStart, setTimerStart] = useState(timerStart)

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN, className);
  }, [className]);

  const clearTimer = () => {
    if (intervalTimer.current) clearInterval(intervalTimer.current)
    if (timeoutTimer.current) clearTimeout(timeoutTimer.current)
  }

  const resetTimer = useCallback((isRepeated) => {
    clearTimer()
    if (isRepeated && onComplete) onComplete()
    if (isRepeated && !loop) return

    setLabel(timer / 1000)
    setTimerStart(Date.now())
    timeoutTimer.current = setTimeout(() => resetTimer(true), timer)
    intervalTimer.current = setInterval(updateTimer, 999)
  }, [timer])

  const updateTimer = useCallback(() => {
    setLabel(time => time - 1)
  }, [timer])

  useEffect(() => {
    if (typeof timer === "number") {
      setLabel(timer / 1000)
      resetTimer()
    } else if (typeof progress === "number") {
      clearTimer()
      setLabel(progress)
    }
  }, [timer, progress, timerStart])

  useEffect(() => clearTimer, [])

  return (
    <div className={getMainClassNames}>
      <svg
        viewBox="0 0 36 36"
        className="circular-chart blue"
      >
        <path
          className="circle-bg"
          d="M18 2.0845
		          a 15.9155 15.9155 0 0 1 0 31.831
		          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className="circle"
          strokeDasharray={'100, 100'}
          d="M18 2.0845
		          a 15.9155 15.9155 0 0 1 0 31.831
		          a 15.9155 15.9155 0 0 1 0 -31.831"
          style={{ animationDuration: `${timer || 0}ms` }}
          key={_timerStart}
        />
        <text x="18" y="22.35" className="percentage">
          {label}
        </text>
      </svg>
    </div>
  );
};

export default memo(AcProgressCircular);