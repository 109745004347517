// Imports => React
import React, { useEffect, useState, useMemo, memo } from 'react';
import { useLocation } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import clsx from 'clsx';

// Imports => Utilities
import { AcSupportsWEBP, AcGetState, AcSaveState, AcClearState } from '@utils';

// Imports => Constants
import { AUTHENTICATION_ROUTES, DEFAULT_ROUTE, KEYS, TYPES } from '@constants';

// Imports => Atoms
import AcImage from '@atoms/ac-image/ac-image.web';

const _CLASSES = {
	MAIN: 'ac-image-wrp--auth',
};

const AcAuthBackground = () => {
	const location = useLocation();
	const [background, setBackground] = useState(null);

	const isAuthRoute = useMemo(
		() =>
			AUTHENTICATION_ROUTES.find(
				(item) => location.pathname.indexOf(item) > -1
			),
		[location]
	);

	useEffect(() => {
		if (isAuthRoute) getRandomBackground();
		else setBackground(null);
	}, [isAuthRoute]);

	const getRandomBackground = async () => {
		const prev = background?.id;
		let id = Math.floor(Math.random() * 5) + 1;

		if (prev) {
			do {
				id = Math.floor(Math.random() * 5) + 1;
			} while (id === prev);
		}

		const imageName = AcSupportsWEBP()
			? `background-n${id}-1x.webp`
			: `background-n${id}-1x.jpg`;
		const source = (await import(`@assets/images/${imageName}`)).default;
		const placeholder = (
			await import(`@assets/images/background-n${id}-1x.json`)
		).default;

		setBackground({ id, source, placeholder });
	};

	const renderBackgroundImage = useMemo(() => {
		if (!isAuthRoute || !background) return null;

		return (
			<AcImage
				source={background.source}
				type={TYPES.BACKGROUND}
				wrpClassName={_CLASSES.MAIN}
				key={background.source}
				placeholderCss={background.placeholder}
			/>
		);
	}, [background, isAuthRoute]);

	return renderBackgroundImage;
};

export default memo(AcAuthBackground);
