// Imports => React
import React, { useCallback, useMemo, memo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

// Imports => Constants
import { DEFAULT_ROUTE, TITLES, VISUALS } from '@constants';

const _CLASSES = {
  MAIN: 'ac-logo',
  CONTAINER: {
    MAIN: 'ac-logo__container',
  },
  VISUAL: {
    MAIN: 'ac-logo__visual',
  },
};

// Component
const AcLogo = ({ callback }) => {
  const handleClick = useCallback(
    (event) => {
      if (event && event.persist) event.persist();

      if (callback) {
        if (event && event.preventDefault) event.preventDefault();
        callback(event);
      }
    },
    [callback]
  );

  const getContainerClassNames = useMemo(() => {
    return clsx(_CLASSES.CONTAINER.MAIN);
  });

  const getStyleClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  });

  const renderLogo = useMemo(() => {
    let Logo = VISUALS.LOGO;

    return <Logo />;
  });

  return (
    <Link
      to={DEFAULT_ROUTE.path}
      className={getStyleClassNames}
      onClick={handleClick}
      title={TITLES.BASE}
    >
      <div className={getContainerClassNames}>{renderLogo}</div>
    </Link>
  );
};

export default memo(AcLogo);
