import React, { useEffect, useState, useMemo, memo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import clsx from 'clsx';

// Imports => Contants
import { ICONS, SIZES, THEMES } from '@constants';

// Imports => Utilities
import { AcUUID, AcIsSet } from '@utils';

// Imports => Atoms
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';

const _CLASSES = {
	MAIN: 'ac-close-button',
	WHITE: 'ac-close-button--white',
	OMEGA: 'ac-close-button--omega',
	LIGHT: 'ac-close-button--light',
	ICON: 'ac-close-button__icon',
};

const AcCloseButton = ({
	callback,
	theme,
	icon = ICONS.CLOSE,
	tooltip = null,
}) => {
	const navigate = useNavigate();
	const [tooltipID, setTooltipID] = useState(AcUUID());

	const handleClick = () => {
		if (callback) callback();
	};

	const getIconClassNames = useMemo(() => {
		return clsx(_CLASSES.ICON);
	}, []);

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN, theme && _CLASSES[theme.toUpperCase()]);
	}, [theme]);

	const additionalProps = useMemo(() => {
		if (!tooltip) return {};

		return {
			'data-tooltip-id': tooltipID,
			'data-tooltip-content': tooltip,
			'data-tip': tooltip,
		};
	}, [tooltip, tooltipID]);

	useEffect(() => {
		if (AcIsSet(tooltip)) {
			ReactTooltip.rebuild();
		}
	}, [tooltip]);

	return (
		<div
			className={getMainClassNames}
			onClick={handleClick}
			{...additionalProps}
		>
			<AcRipple theme={THEMES.PITCH} size={SIZES.SMALL} simple />
			<AcIcon icon={icon} className={getIconClassNames} />
			{tooltip && <ReactTooltip id={tooltipID} />}
		</div>
	);
};

export default memo(AcCloseButton);
