import React, { useCallback, useMemo, memo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Fade } from 'react-awesome-reveal';
import clsx from 'clsx';

// Imports => Config
import config from '@config';

// Imports => Constants
import { ICONS, KEYS, SIZES, THEMES, TYPES, VARIANTS } from '@constants';

// Imports => Utilities
import { AcIsSet, AcGetHumanizedBytesDisplay } from '@utils';

// Imports => Atoms
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
  MAIN: 'ac-dropzone ac-dropzone--file',
  DRAGGING: 'ac-dropzone--dragging',
  INSTRUCTIONS: 'ac-dropzone__instructions',
};

const AcFileDropzone = ({
  callback,
  subtext = 'XLS or XLSX',
  accept = 'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  maxSize = config.maxFileSize,
  maxFiles = 1,
  loading,
}) => {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files

    if (AcIsSet(acceptedFiles) && AcIsSet(acceptedFiles[0])) {
      if (callback) callback(acceptedFiles[0]);
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop,
    maxFiles,
    maxSize, // 4 MB in binary
    accept,
  });

  const getInstructionsClassNames = useMemo(() => {
    return clsx(_CLASSES.INSTRUCTIONS);
  }, []);

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN, isDragActive && _CLASSES.DRAGGING);
  }, [isDragActive]);

  const renderFileError = useMemo(() => {
    if (!fileRejections || !fileRejections.length) return null;

    let error = null;
    let code = null;

    if (fileRejections && fileRejections.length > 1) code = 'too-many-files';
    else if (fileRejections[0] && fileRejections[0].errors) {
      code = fileRejections[0].errors[0].code;
    }

    switch (code) {
      case 'too-many-files':
        error = 'Please upload only 1 file.';
        break;

      case 'file-invalid-type':
        error = `Invalid file type. Allowed type(s): <strong>${subtext}</strong>.`;
        break;

      case 'file-too-large':
        error = 'File size is too big.';
        break;

      default:
        error = `Something has gone wrong. Please try again.`;
    }

    return (
      <div className={'ac-dropzone__error'}>
        <AcIcon icon={ICONS.PROGRESS_ALERT} />{' '}
        <span
          dangerouslySetInnerHTML={{
            __html: error,
          }}
        />
      </div>
    );
    // return <div>{}</div>;
  }, [fileRejections, subtext]);

  const renderInstructions = useMemo(() => {
    if (isDragActive) {
      return (
        <div className={getInstructionsClassNames}>
          <h4>Drop your file here</h4>
        </div>
      );
    } else if (AcIsSet(acceptedFiles) && AcIsSet(acceptedFiles[0])) {
      let { size, name } = acceptedFiles[0];
      size = AcGetHumanizedBytesDisplay(size);

      return (
        <div className={getInstructionsClassNames}>
          <p
            dangerouslySetInnerHTML={{
              __html: `Selected file: <strong>${name}</strong>`,
            }}
          />
          <p
            className={'subtext'}
            dangerouslySetInnerHTML={{
              __html: `File size: <strong>${size}</strong>`,
            }}
          />
        </div>
      );
    }

    return (
      <div className={getInstructionsClassNames}>
        <h4>Drag and drop a file here</h4>
        <p>
          or <u>click</u> to browse
        </p>
        <p className={'subtext'}>{subtext}</p>
      </div>
    );
  }, [isDragActive, acceptedFiles, subtext]);

  return (
    <div className={getMainClassNames} {...getRootProps()}>
      <input {...getInputProps()} />

      {renderInstructions}

      {renderFileError}

      <AcRipple theme={THEMES.PITCH} size={SIZES.LARGE} simple />

      <AcLoader loading={loading} cover />
    </div>
  );
};

export default memo(AcFileDropzone);
