import React, { forwardRef, useEffect, useState } from 'react';
import clsx from 'clsx';

const AcInputAdornment = forwardRef(
  ({ classes, position, hideWhen, disablePointerEvents, children, ...rest }, ref) => {
    const [shouldHide, setShouldHide] = useState(hideWhen || false);

    useEffect(() => {
      setShouldHide(hideWhen);
    }, [hideWhen]);

    return (
      <div
        ref={ref}
        className={clsx(
          'ac-input-adornment',
          disablePointerEvents ? 'disabled' : '',
          shouldHide ? 'auto-hide' : '',
          classes
        )}
        {...rest}
      >
        {children}
      </div>
    );
  }
);

export default AcInputAdornment